<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <!-- <rekapitulasi-kehadiran :tableColumns="columns3" :tableDatas="data" /> -->
            <template v-if="menuActive === 1">
              <periode-kehadiran />
            </template>
            <template v-if="menuActive === 2">
              <rekapitulasi-kehadiran />
            </template>
            <template v-if="menuActive === 3">
              <log-kehadiran />
            </template>
            <template v-if="menuActive === 4">
              <pengajuan-cuti />
            </template>
            <template v-if="menuActive === 5">
              <persetujuan-izin />
            </template>
            <template v-if="menuActive === 6">
              <persetujuan-lembur />
            </template>
            <template v-if="menuActive === 9">
              <div>
                Pengaturan
              </div>
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import moment from 'moment'
const Menu = () => import('@/components/app/MenuV2')
const periodeKehadiran = () => import('@/views/AdminHR/Attendance/PeriodeKehadiran')
const rekapitulasiKehadiran = () => import('@/views/AdminHR/Attendance/RekapitulasiKehadiran')
const LogKehadiran = () => import('@/views/AdminHR/Attendance/LogKehadiran')
const PengajuanCuti = () => import('@/views/AdminHR/Attendance/PengajuanCuti')
const persetujuanIzin = () => import('@/views/AdminHR/Attendance/PersetujuanIzin')
const PersetujuanLembur = () => import('@/views/AdminHR/Attendance/PersetujuanLembur')

const menus = [
  {
    key: 1,
    menu: 'Periode Kehadiran',
  },
  {
    key: 2,
    menu: 'Rekapitulasi Kehadiran',
  },
  {
    key: 3,
    menu: 'Log Kehadiran',
  },
  {
    key: 4,
    menu: 'Persetujuan Cuti',
    // notification: true,
  },
  {
    key: 5,
    menu: 'Persetujuan Izin',
  },
  {
    key: 6,
    menu: 'Persetujuan Lembur',
  },
  // {
  //   key: 7,
  //   menu: 'Pengaturan',
  // },
]
const columns = [
  {
    title: 'Nama Pegawai',
    dataIndex: 'name',
    key: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Unit',
    dataIndex: 'unit',
    key: 'unit',
  },
  {
    title: 'Kehadiran',
    dataIndex: 'attendance',
    key: 'attendance',
    children: [
      {
        title: 'Masuk',
        dataIndex: 'inTime',
        key: 'inTime',
        align: 'center',
      },
      {
        title: 'Keluar',
        dataIndex: 'outTime',
        key: 'outTime',
        align: 'center',
      },
    ],
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
    align: 'center',
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'Attendance',
  components: {
    Menu,
    periodeKehadiran,
    rekapitulasiKehadiran,
    PersetujuanLembur,
    PengajuanCuti,
    persetujuanIzin,
    LogKehadiran,
  },
  data() {
    return {
      visibleModalExport: false,
      confirmLoadingModalAttendance: false,
      menus,
      columns,
      today: moment(),
      // menuActive: 'Recapitulation',
      viewLog: 'Month',
      periodData: 'This Month',
      menuActive: 1,
      startAt: '',
      endAt: '',
      endOpen: false,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
    handleViewLog(e) {
      this.viewLog = e.target.value
      console.log(this.viewLog)
    },
    disabledStartDate(startValue) {
      const endValue = this.endAt
      if (!startValue || !endValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    disabledEndDate(endValue) {
      const startValue = this.startAt
      if (!endValue || !startValue) {
        return false
      }
      return startValue.valueOf() > endValue.valueOf()
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    handleVisibleModalExport() {
      this.visibleModalExport = !this.visibleModalExport
    },
    handleOkModalExport() {
      console.log('Exported')
    },
    dateOnChange(e) {
      console.log(e)
    },
    periodOnChange(value) {
      this.periodData = value
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
}
</script>

<style lang="scss" module>
</style>
